export const ADMIN = "admin";
export const USER = "user";

export const USER_TYPE = {
  SUPER_ADMIN: 1,
  SALES_ADMIN: 2,
  FINANCE_MANAGER: 3,
  MIS_MANAGER: 4,
  TRANSPORTER_MANAGER: 5,
  TRANSPORTERS: 6,
  DRIVERS: 7,
  DESTINATION_PARTY: 8,
  QUALITY_CONTROL: 9,
};

export const USER_NAME = [
  {
    label: "Super Admin",
    value: 1,
  },
  {
    label: "Sales Admin",
    value: 2,
  },
  {
    label: "Finance Manager",
    value: 3,
  },
  {
    label: "Mis Manager",
    value: 4,
  },
  {
    label: "Transporter Manager",
    value: 5,
  },
  {
    label: "Transporters",
    value: 6,
  },
  {
    label: "Drivers",
    value: 7,
  },
  {
    label: "Destination Party",
    value: 8,
  },
  {
    label: "Quality Control",
    value: 9,
  },
];

export const GET_POST_USER_TYPE = {
  DRIVERS: 1,
  SUB_ADMIN: 2,
  DESTINATION_PARTY: 3,
  TRANSPORTERS: 4,
  QUALITY_CONTROL: 5,
  FINANCE_MANAGER: 6,
  MIS_MANAGER: 7,
  SALES_ADMIN: 8,
  TRANSPORTER_MANAGER: 9,
  TRIPS: 10,
};

export const NOTIFICATION_TYPE = {
  TRIP_UPDATES: 1,
  TRIP_ASSIGNED: 2,
  TRIP_ISSUE_HALT: 3,
  DRIVER_LICENSE_END: 4,
  TRANSPORTER_AGGREMENT_END: 5,
};

export const BILL_TYPES = {
  FUEL_DIESEL: 1,
  FUEL_CNG: 8,
  TOLL_TAX: 2,
  ROAD_TAX: 3,
  AIR_CHECK_EXPENSE: 4,
  PUNCTURE_EXPENSE: 5,
  MISCELLANEOUS_IN_TRANSIT: 6,
  MISCELLANEOUS_AT_DESTINATION: 7,
  MISCELLANEOUS_PER_CONTRACT: 9,
  DEDUCTIONS: 10,
};
